<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="card card-custom card-stretch">
                    <div class="card-body pt-4">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <div class="col-md-6">
                                <v-text-field
                                    v-model="name"
                                    :rules="[v => !!v || 'Cần phải nhập tên']"
                                    label="Tên"
                                    class="required"
                                    required
                                ></v-text-field>
                            </div>

                            <div class="col-md-6">
                                <v-text-field
                                    v-model="slug"
                                    label="Slug"
                                    :rules="[v => !!v || 'Cần phải nhập slug']"
                                    class="required"
                                    :required="true"
                                ></v-text-field>
                            </div>
                            
                            <div class="col-md-6">
                                <v-btn
                                    class="ma-2 float-right"
                                    :loading="loading"
                                    color="info"
                                    @click="create_tag"
                                >
                                    Thêm
                                </v-btn>

                                <v-btn
                                    class="ma-2 float-right"
                                    :loading="loading"
                                    @click="cancel"
                                >
                                    Huỷ
                                </v-btn>
                            </div>

                        </v-form>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    metaInfo: {
        title: 'Thêm từ khoá'
    },
    data() {
        return {
            valid: true,
            name: "",
            slug: "",
            loading: false,
            name_rules: [],
        };
    },
    watch: {
        
    },
    computed: {
        
    },
    components: {
        
    },
    methods: {
        create_tag() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                var payload = {
                    Name: this.name,
                    Slug: this.slug,
                    toast: this.$root.$bvToast,
                }
                this.$store.dispatch('tags/create_tag', payload).then(() => {
                    this.loading = false;
                    this.$router.push({ name: 'tags-index' })
                }).catch(() => {
                    this.loading = false;
                });
            }
        },
        cancel() {
            this.$router.push({ name: 'tags-index' });
        },
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Từ khoá", route: "index" }, { title: "Thêm từ khoá" } ]);
    }
};
</script>



